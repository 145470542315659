export const ChristmasLayoutVariations = {
    variationA: 'slider',
    variationB: 'him_her',
    variationC: 'gifty',
};

export const combinedArticleTest = {
    flagKey: 'b4-combined-article-dropdown',
    variationA: 'dropdown',
    variationB: 'list',
};
