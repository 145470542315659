import type React from 'react';
import styles from './GiftyHelper.module.scss';
import Button from '../../../../stories/Atoms/Button/Button';
import ContentBlock from '../../../../stories/Molecules/ContentBlock/ContentBlock';
import Picture from '../../Picture/Picture';
import Source from '../../Picture/Source';
import Icon from '../../../../stories/Atoms/Icon/Icon';
import Gifty from '../../../../js/gifty/gifty';
import { useWithStaticUrl } from '../../WebshopContext/WebshopContext';

const GiftyHelper: React.FC = () => {
    const withStaticUrl = useWithStaticUrl();

    const openGifty = () => {
        const gifty = new Gifty();
        gifty.init('startGiftFinderChristmas');
    };

    return (
        <div className={styles.GiftyHelper}>
            <div className={styles.GiftyHelper__image}>
                <Picture alt="Kerstcadeau 2024" imageUrl={withStaticUrl('/resourcebase/files/542/1400040.png')} lazyLoad={false}>
                    <Source framing={false} imageWidth={768} imageHeight={320} minScreenWidth={768} smartImage />
                    <Source framing={false} imageWidth={320} imageHeight={200} />
                </Picture>
            </div>
            <div className={styles.GiftyHelper__content}>
                <div className={styles.GiftyHelper__content__block}>
                    <Icon name="fa-tree-christmas" style="fa-duotone" size="fa-xl"/>
                    <ContentBlock className="mt-6 mb-6" title="Nieuw" content="Onze enige echte santa-helper is er! Wij staan te popelen om jouw cadeau te helpen vinden." />
                    <Button onClick={openGifty} rounded>Vind jouw cadeau</Button>
                </div>
            </div>
        </div>
    );
};

export default GiftyHelper;
