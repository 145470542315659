import { z } from 'zod';

export const CxJsonDataScheme = z.object({
    active: z.boolean(),
    algoliaIndex: z.string(),
    baseUrl: z.string(),
    currency: z.string(),
    customerServiceEmail: z.string(),
    customerServicePhone: z.string(),
    defaultShippingCountry: z.string(),
    floatingId: z.string(),
    inlineId: z.string(),
    pageId: z.string().nullable().optional(),
    webStoreContextVariable: z.string(),
    webStoreConversationVariable: z.string(),
});

export const SetConversationVariableMetadataScheme = z.object({
    key: z.string(),
    value: z.string(),
});

export const ChatbotStateScheme = z.object({
    state: z.object({
        minimized: z.boolean(),
        visible: z.boolean(),
    }),
});

export type CxJsonData = z.infer<typeof CxJsonDataScheme>;

export type SetConversationVariableMetadata = z.infer<typeof SetConversationVariableMetadataScheme>;

export type ChatbotState = z.infer<typeof ChatbotStateScheme>;
