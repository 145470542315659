import type { FC, PropsWithChildren, RefObject } from 'react';
import { useRef, useEffect } from 'react';
import classNames from 'classnames';
import styles from './Dialog.module.scss';
import Icon from '../../Atoms/Icon/Icon';
import Backdrop from '../../Atoms/Backdrop/Backdrop';
import { hasDialogSupport } from '../../../js/util/ElementSupport';
import Button from '../../Atoms/Button/Button';

type DialogSize = 'sm' | 'lg' | 'auto';

interface Props {
    centered?: boolean;
    className?: string;
    closeButton?: boolean;
    dialogRef?: RefObject<HTMLDialogElement>;
    isFullHeight?: boolean;
    large?: boolean;
    modal?: boolean;
    noPaddingOnBody?: boolean;
    onClose?: () => void;
    open: boolean;
    size?: DialogSize;
}

const Header: FC<PropsWithChildren> = ({ children }) => <div className={styles.Dialog__Header}>{children}</div>;
const Body: FC<PropsWithChildren> = ({ children }) => <div className={styles.Dialog__Body}>{children}</div>;
const Footer: FC<PropsWithChildren<{ showFooterBackground?: boolean }>> = ({ children, showFooterBackground }) => (
    <div className={classNames(styles.Dialog__Footer, { [styles.Dialog__Footer_withBackground as string]: showFooterBackground })}>
        {children}
    </div>
);

type DialogComponent = FC<PropsWithChildren<Props>> & {
    Body: typeof Body;
    Footer: typeof Footer;
    Header: typeof Header;
};

const getSizeClasses = (size?: DialogSize): Record<string, boolean> => ({
    [styles.Dialog_Small as string]: size === 'sm',
    [styles.Dialog_Large as string]: size === 'lg',
});

const Dialog: DialogComponent = ({
    centered,
    children,
    className,
    closeButton = false,
    dialogRef,
    isFullHeight = false,
    modal = false,
    noPaddingOnBody = false,
    onClose,
    open,
    size = 'sm',
}) => {
    const fallbackDialogRef = useRef<HTMLDialogElement>(null);
    const hasSupport = hasDialogSupport();

    const ref = dialogRef ?? fallbackDialogRef;

    useEffect(() => {
        const dialog = ref.current;
        if (open) {
            if (modal) {
                dialog?.showModal?.();
            } else {
                dialog?.show?.();
            }
            dialog?.querySelector('button')?.blur();
        } else {
            dialog?.close?.();
        }

        return (): void => dialog?.close?.();
    }, [open, ref, hasSupport, modal]);

    const content = (
        <div className={classNames(
            styles.Dialog__Content,
            {
                [styles.Dialog__Content_HasClose as string]: closeButton,
                [styles.Dialog__Content_NoPadding as string]: noPaddingOnBody,
            },
        )}>
            {closeButton && (
                <Button onClick={onClose} size="small" aria-label="Close" square className={styles.Dialog__closeButton}>
                    <Icon name="fa-xmark"/>
                </Button>
            )}
            {children}
        </div>
    );

    if (!hasSupport && open) {
        return (
            <Backdrop onClose={onClose}>
                <div role="dialog" className={
                    classNames(
                        styles.Dialog,
                        styles.Dialog_Unsupported,
                        className,
                        getSizeClasses(size),
                    )}>
                    {content}
                </div>
            </Backdrop>
        );
    }

    return (
        <Backdrop isOpen={open} onClose={onClose}>
            <dialog
                autoFocus
                onClick={onClose}
                className={classNames(
                    styles.Dialog,
                    className,
                    { [styles.Dialog_Centered as string]: centered },
                    { [styles.Dialog_FullHeight as string]: isFullHeight },
                    getSizeClasses(size),
                )}
                ref={ref}
            >
                {/**
                 The dialog element covers the entire viewport and resides in the top layer.
                 This prevents the 'click outside' behaviour from the <Backdrop> component because it is behind the dialog.
                 To fix this, we close the modal if the user clicks outside the main content div (aka, the backdrop).
                 */}
                <div onClick={(e) => e.stopPropagation()}>
                    {content}
                </div>
            </dialog>
        </Backdrop>
    );
};

Dialog.Header = Header;
Dialog.Body = Body;
Dialog.Footer = Footer;

export default Dialog;
