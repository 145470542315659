import { document, window } from '../globals';
import { isObject } from '../typeguards';
import { localStorage } from '../util/storage';
import { isChatbotState, isCxJsonData, isSetConversationVariableMetadata } from './typeguards';
import type { ChatbotState, CxJsonData } from './types';

export default class Gifty {
    private static readonly SKIPPED_INTERACTION_MESSAGE = 'Notice: System skipped interaction';
    private readonly FLOATING_WIDGET_URL = 'https://webchat.digitalcx.com/webchat.js';
    private readonly INLINE_WIDGET_URL = 'https://cxcomlive-webconvwebchatwa-weu.azurewebsites.net/inline.js';
    private readonly BUTTON_SELECTOR = '.cm-button-js';
    private readonly INLINE_SELECTOR = '.cm-inline-js';

    public JSON_DATA?: CxJsonData;
    public FLOATING_BUTTON: HTMLButtonElement | null | undefined;
    public INLINE_WRAPPER: HTMLDivElement | null | undefined;

    constructor() {
        this.FLOATING_BUTTON = document?.querySelector(this.BUTTON_SELECTOR);
        this.INLINE_WRAPPER = document?.querySelector(this.INLINE_SELECTOR);

        if (!(this.FLOATING_BUTTON instanceof HTMLButtonElement)) {
            return;
        }

        const jsonData = JSON.parse(this.FLOATING_BUTTON.getAttribute('data-cxJsonData') ?? '{}') as CxJsonData;

        if (jsonData !== undefined && isCxJsonData(jsonData)) {
            this.JSON_DATA = jsonData;
        }
    }

    public init(event: string = 'onload'): void {
        const jsonData = this.JSON_DATA;

        if (isCxJsonData(jsonData) && this.FLOATING_BUTTON) {
            if (jsonData.inlineId && this.INLINE_WRAPPER) {
                this.loadFloatingScript(jsonData, event).then(() => null).catch(() => null);
            }

            if (event !== 'onload') {
                this.loadFloatingScript(jsonData, event)
                    .then((floatingId) => {
                        Gifty.toggleFloatingChat(floatingId);
                    })
                    .catch(() => null);
            }

            this.FLOATING_BUTTON.addEventListener('click', () => {
                this.loadFloatingScript(jsonData, event)
                    .then((floatingId) => {
                        Gifty.toggleFloatingChat(floatingId);
                    })
                    .catch(() => null);
            });
        }
    }

    private static getState(floatingId: string): ChatbotState | null {
        const appItem = localStorage.getItem(`${floatingId}_${floatingId}.app`);
        const jsonParse = JSON.parse(appItem ?? '{}') as Record<string, unknown>;

        if (isChatbotState(jsonParse)) {
            return jsonParse;
        }

        return null;
    }

    // Inline variant
    public loadFloatingScript(jsonData: CxJsonData, event: string = 'onload'): Promise<string> {
        return new Promise((resolve) => {
            if (!window || !document) {
                return;
            }

            if (document.querySelector(`script[src="${this.FLOATING_WIDGET_URL}"]`)) {
                resolve(jsonData.floatingId);
                window?.cmwc?.get(jsonData.floatingId)?.sendEvent(event);

                return;
            }

            const scriptElement = document.createElement('script');
            scriptElement.src = this.FLOATING_WIDGET_URL;
            scriptElement.crossOrigin = 'anonymous';
            scriptElement.onload = async () => {
                await new Promise((resolveFloating) => {
                    let floatingInstance;

                    if (!window?.cmwc?.get(jsonData.floatingId)) {
                        floatingInstance = window?.cmwc?.add(jsonData.floatingId, () => {
                            if (window?.siteMetadata?.language) {
                                window?.cmwc?.get(jsonData.floatingId)?.setLanguage(
                                    window.siteMetadata.language.toLowerCase() === 'no' ? 'nb' : window.siteMetadata.language.toLowerCase(),
                                );
                            }

                            Gifty.addInitialWebStoreContext(jsonData.webStoreContextVariable, jsonData.floatingId);
                            Gifty.addInitialPageTypeContextVariable(jsonData.floatingId);
                            Gifty.addInitialWebStoreConversationVariable(jsonData);

                            window?.cmwc?.get(jsonData.floatingId)?.sendEvent(event);
                        });

                        Gifty.onAnswer(jsonData.floatingId);
                    }

                    // Wait for the floating script to be loaded; if there is an inlineId and inline wrapper, load the inline script
                    if (jsonData.inlineId && this.INLINE_WRAPPER) {
                        this.loadInlineScript(jsonData);
                    } else {
                        floatingInstance?.install();
                    }

                    resolveFloating(null);
                });

                resolve(jsonData.floatingId);
            };

            document.body.appendChild(scriptElement);
        });
    }

    static addInitialWebStoreContext(contextVariable: string, floatingId: string): void {
        const chatBotInstance = window?.cmwc?.get(floatingId);
        if (chatBotInstance && isObject(chatBotInstance)) {
            const webStoreContextVariable = contextVariable;

            if (webStoreContextVariable) {
                chatBotInstance.addContext({ webStore: webStoreContextVariable });
            }
        }
    }

    static addInitialWebStoreConversationVariable(jsonData: CxJsonData): void {
        const chatBotInstance = window?.cmwc?.get(jsonData.floatingId);

        if (chatBotInstance && isObject(chatBotInstance)) {
            chatBotInstance.addConversationVariables({
                algoliaIndex: jsonData.algoliaIndex,
                baseUrl: jsonData.baseUrl,
                customerServiceEmail: jsonData.customerServiceEmail,
                customerServicePhone: jsonData.customerServicePhone,
                defaultShippingCountry: jsonData.defaultShippingCountry,
                pageId: jsonData.pageId ?? '0',
                webStore: jsonData.webStoreConversationVariable,
            });
        }
    }

    static addInitialPageTypeContextVariable(floatingId: string): void {
        const chatBotInstance = window?.cmwc?.get(floatingId);
        if (chatBotInstance && isObject(chatBotInstance)) {
            const pageType = window?.pageType.replaceAll(' ', '_');

            if (pageType) {
                chatBotInstance.addContext({ pageType });
            }
        }
    }

    public static toggleFloatingChat(floatingId: string) {
        const chatState = Gifty.getState(floatingId)?.state;
        const chatBotInstance = window?.cmwc?.getByInstanceKey(`${floatingId}_${floatingId}`);

        if (!chatState || !chatBotInstance) {
            return;
        }

        // Gifty doesn't properly set the state of 'visible' after closing
        // In which case we have to open the chat rather than show it
        if (chatState.visible && chatState.minimized) {
            chatBotInstance.open();
            chatBotInstance.show();

            return;
        }

        if (!chatState.visible) {
            chatBotInstance.open();
            chatBotInstance.show();

            return;
        }

        chatBotInstance.close();
        chatBotInstance.hide();
    }

    private static onAnswer(floatingId: string) {
        const chatBotInstance = window?.cmwc?.get(floatingId);
        if ((chatBotInstance && isObject(chatBotInstance))) {
            chatBotInstance.onAnswer((event) => {
                if (event.detail.chat.metadata.data?.outputAdditions?.setConversationVariable) {
                    const obj = JSON.parse(event.detail.chat.metadata.data.outputAdditions.setConversationVariable) as unknown;

                    if (isSetConversationVariableMetadata(obj)) {
                        chatBotInstance.addConversationVariables({ [obj.key]: obj.value });
                    }
                }

                if (event.detail.chat.metadata.data?.outputAdditions?.skipInteraction === 'true') {
                    chatBotInstance.caic.askHidden(this.SKIPPED_INTERACTION_MESSAGE);
                }
            });
        }
    }

    // Floating variant
    private loadInlineScript(jsonData: CxJsonData): void {
        if (!jsonData.inlineId || !window || !document) {
            return;
        }

        const scriptElement = document.createElement('script');
        scriptElement.type = 'module';
        scriptElement.onload = () => {
            if (!window?.cmwc?.getByInstanceKey(`${jsonData.floatingId}_${jsonData.inlineId}`)) {
                window?.cmwc?.addShared({ config: jsonData.floatingId, style: jsonData.inlineId }, () => {}).install();

                window?.cmwc?.get(jsonData.floatingId)?.hide();
                window?.cmwc?.get(jsonData.floatingId)?.close();
            }
        };

        scriptElement.src = this.INLINE_WIDGET_URL;

        document.body.appendChild(scriptElement);
    }
}
